import React from 'react'
import './css/loader.css';

function Loader() {
    return (
        <>
            <div className="loading"></div>
        </>
    )
}

export default Loader