export const testimonialsData = [
  {
    id: 1,
    name: "Kevin C ",
    comment:
      "⦁	Smart Learner helped my daughter through her driver theory test via online learning classes. She responded really well, and got a pass on her first attempt following her training with Smart Learner. Would highly recommend this service!",
    image: "src/assets/images/kevin-img-150x150.jpg",
  },
  {
    id: 2,
    name: "Daniel H ",
    comment:
      "⦁	After failing my theory I started learning with smart learner driving  school for theory. My teacher she was very calm, friendly and systematic from the beginning. Taught me very nicely, made sure I understood completely before moving on to the next topic. I passed first time and would highly recommend.",
    image: "src/assets/images/pete-img-150x150.jpg", // URL to the image
  },
  {
    id: 3,
    name: "Elizabeth S",
    comment:
      "I PASSED MY THEORY TEST! I had taken my theory text 4 times and failed on the hazard perception. I took 4 classes with SmartLearner in their office and they showed me all the tips and tricks on how to pass the test. I took my test this morning and passed! Thank you SmartLearner!!!",
    image: "src/assets/images/xain-img-150x150.jpg", // URL to the image
  },
  {
    id: 4,
    name: "WAID",
    comment:
      "The Service provided by Smartlearners was excellent, from booking my first driving lesson to being on the road. The instructors was patient, polite and punctual, with communication skills being exemplary throughout my driving skills.",
    image: "src/assets/images/waid-img-150x150.jpg", // URL to the image
  },
  {
    id: 5,
    name: "JAMES",
    comment:
      "Thanks to smartlearners for helping me pass my driving test. The facilities offered by the school were good with clean cars, mod con driving apps, responsive booking service”. “The service provided by the school was brilliant from booking my first lesson to passing. The instructor put me at ease by being patient. I would recommend the school to others as they use modern technology to teach",
    image: "src/assets/images/james-img-150x150.jpg", // URL to the image
  },
];

export const InstructorTestimonials = [
  {
    id: 1,
    name: "Kevin C ",
    comment:
      "I have recently finished my instructor training with Laila from SmartLearner and I can honestly say she has made the experience extremely enjoyable for me. When beginning this journey, I was nervous and didn’t know what to expect, Laila put my mind and ease and broke everything down in each lesson. SmartLearner also provided a large number of other materials to practice at home and Laila was on hand to answer any questions I had even out of lesson time. Thank you SmartLearner, I would definitely recommend! ",
    image: "src/assets/images/kevin-img-150x150.jpg",
  },
  {
    id: 2,
    name: "Daniel H ",
    comment:
      "I had a great experience completing my ADI training with Moh from Smartlearner. He was always on time and ready to help with any questions I had. Moh has a unique teaching style and I feel this made the content to understand and helped a lot. I would highly recommend Moh and Smartlearner and I will defiantly be back in the future if I need to refresh my skills!  ",
    image: "src/assets/images/pete-img-150x150.jpg", // URL to the image
  },
  {
    id: 3,
    name: "Raj",
    comment:
      "Wow, what can I say about Raj! I have completed my part 3 training with Raj, and I can honestly say I have enjoyed the experience from start to finish! Raj has made me feel at ease on every session and has taken time outside of lessons to help me understand things better. Smartlearner were efficient in helping me with all of my paperwork and no problem was too big for them to help with! If I could give 10 stars, then I would! Thank you, Raj! ",
    image: "src/assets/images/xain-img-150x150.jpg", // URL to the image
  },
];


export const joinOurTeamTestimonials = [
  {
    id: 1,
    name: "Kevin C ",
    comment:
      "Being part of a franchise with Smartlearner Driving School has been amazing so far! The training and support made everything super easy. As a well-known school, they have provided lots of students, and the community is always there to help. It's been a great experience, both professionally and personally. ",
    image: "src/assets/images/kevin-img-150x150.jpg",
  },
  {
    id: 2,
    name: "Daniel H ",
    comment:
      "Joining Smartlearner franchise has been a game-changer for me, and the fact they offer CPD opportunities is a huge bonus. It’s helped me keep my skills sharp and up to date, making me more confident in my job. The new techniques and knowledge I’ve gained have opened new opportunities for me and I feel they have been incredibly valuable for my career growth. ",
    image: "src/assets/images/pete-img-150x150.jpg", // URL to the image
  },
 
];
