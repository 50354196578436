export const products = [
    {
      id: 1,
      name: 'Product 1',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 100,
      stock: 10,
      category:"Theory",
      duration: '4hours',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
      discription: "sajdklsjadhsakjdcbsakmcjbsamkcjsbadksahguduasyhd"
    },
    {
      id: 2,
      name: 'Product 2',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SPT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      rating: 4.5,
    },
    {
      id: 3,
      name: 'Product 3',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Theory",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },

    {
      id: 4,
      name: 'Product 4',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SPT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      rating: 4.5,
    },
    {
      id: 5,
      name: 'Product 5',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Theory",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 6,
      name: 'Product 6',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Theory",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 7,
      name: 'Product 7',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SPT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 8,
      name: 'Product 8',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Intensive",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 9,
      name: 'Product 9',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"ADI",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 10,
      name: 'Product 10',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Intensive",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
   
    {
      id: 11,
      name: 'Product 11',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 100,
      stock: 10,
      category:"Theory",
      duration: '4hours',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 12,
      name: 'Product 12',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Theory",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      rating: 4.5,
    },
    {
      id: 13,
      name: 'Product 13',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"ADI",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },

    {
      id: 14,
      name: 'Product 14',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Intensive",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      rating: 4.5,
    },
    {
      id: 15,
      name: 'Product 15',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"Theory",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 16,
      name: 'Product 16',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"ADI",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 17,
      name: 'Product 17',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SMT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 18,
      name: 'Product 18',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SMT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 19,
      name: 'Product 19',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"ADI",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
    {
      id: 20,
      name: 'Product 20',
      image: 'src/assets/images/atomatic-driving-img-1.jpg',
      price: 99,
      stock: 10,
      category:"SMT",
      duration: '6 weeks',
      experience: 'Beginner/Experienced',
      Transmission: 'Automatic/Manual',
      postcode: ['B10','B11', 'B12', 'B13', 'B25', 'B26', 'B27', 'B28', 'B33', 'B34', 'B36', 'B37', 'B40', 'B5','B8','B9','B91','B92','CV1','CV10', 'CV11','CV12','CV2', 'CV3','CV31', 'CV32','CV34','CV4','CV5','CV6','CV7','CV8' ,'LE17'],
      areaIncluded: ['Balsall Common', 'Bedworth', 'Berkswell', 'Bulkington','Coventry','Meriden','Nuneaton', 'Solihull'],
      rating: 4.5,
    },
   

    // Add more products here
  ];